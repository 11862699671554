import React, { useContext } from 'react'
import { DarkModeContext } from '../../context/darkModeContext';

import './clients.scss'

const ClientsSectionOne = () => {
  const { darkMode, toggleDarkMode } = useContext(DarkModeContext)

  return (
    <div className='about__first-section' style={{height: 'calc(80vh - 140px)'}}>
      <div style={{ maxWidth: '100%' }}>
        <div className={`about__first-section__heading ${darkMode ? 'dark-mode__color1' : 'light-mode__color1'}`} style={{ marginTop: '20px' }}>
          Clients
        </div>
        <div className="about__first-section__bulb" style={{ marginTop: '25px' }} onClick={toggleDarkMode}>
          <svg style={{ maxWidth: '85vw' }} width="410" height="217" viewBox="0 0 410 217" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M205 28.3333C212.514 28.3333 219.721 31.3184 225.035 36.6319C230.348 41.9454 233.333 49.1521 233.333 56.6666C233.333 64.181 230.348 71.3877 225.035 76.7013C219.721 82.0148 212.514 84.9999 205 84.9999C197.486 84.9999 190.279 82.0148 184.965 76.7013C179.652 71.3877 176.667 64.181 176.667 56.6666C176.667 49.1521 179.652 41.9454 184.965 36.6319C190.279 31.3184 197.486 28.3333 205 28.3333ZM205 99.1666C236.308 99.1666 261.667 111.846 261.667 127.5V141.667H148.333V127.5C148.333 111.846 173.692 99.1666 205 99.1666Z" fill="#FFE108" />
            <path d="M325 75.3333C332.514 75.3333 339.721 78.3184 345.035 83.6319C350.348 88.9454 353.333 96.1521 353.333 103.667C353.333 111.181 350.348 118.388 345.035 123.701C339.721 129.015 332.514 132 325 132C317.486 132 310.279 129.015 304.965 123.701C299.652 118.388 296.667 111.181 296.667 103.667C296.667 96.1521 299.652 88.9454 304.965 83.6319C310.279 78.3184 317.486 75.3333 325 75.3333ZM325 146.167C356.308 146.167 381.667 158.846 381.667 174.5V188.667H268.333V174.5C268.333 158.846 293.692 146.167 325 146.167Z" fill="#FFE108" />
            <path d="M85 75.3333C92.5145 75.3333 99.7212 78.3184 105.035 83.6319C110.348 88.9454 113.333 96.1521 113.333 103.667C113.333 111.181 110.348 118.388 105.035 123.701C99.7212 129.015 92.5145 132 85 132C77.4855 132 70.2788 129.015 64.9653 123.701C59.6518 118.388 56.6667 111.181 56.6667 103.667C56.6667 96.1521 59.6518 88.9454 64.9653 83.6319C70.2788 78.3184 77.4855 75.3333 85 75.3333ZM85 146.167C116.308 146.167 141.667 158.846 141.667 174.5V188.667H28.3333V174.5C28.3333 158.846 53.6917 146.167 85 146.167Z" fill="#FFE108" />
          </svg>
        </div>
      </div>
    </div>
  )
}

export default ClientsSectionOne
