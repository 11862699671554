import React from 'react'
import './bulb.css'

export function Bulb({ animate, isMobile }) {
  return (
    <svg width="541" height={isMobile ? "300" : "731"} viewBox="0 0 541 731" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M251.327 83.7687C360.921 87.594 437.228 190.936 438.762 265.551C439.706 311.575 411.995 337.397 406.628 424.504C404.9 452.781 405.872 481.158 409.53 509.251C344.406 520.201 280.791 538.755 219.99 564.531C213.159 537.963 202.135 512.654 187.333 489.559C162.091 450.49 138.609 438.427 114.541 412.782C82.3799 378.529 48.1133 318.762 50.7815 262.38C54.7384 178.777 141.082 79.9108 251.327 83.7687Z" fill="#FBEF81" />
      <path d="M224.674 561.92C224.091 562.49 218.343 568.316 219.648 576.777C221.148 586.241 230.028 589.939 230.46 590.082C285.368 568.455 342.463 552.854 400.74 543.555C404.223 542.177 407.198 539.759 409.259 536.63C411.319 533.501 412.366 529.813 412.256 526.068C411.797 516.625 403.497 511.768 402.948 511.46C341.692 520.902 281.83 537.846 224.712 561.909L224.674 561.92Z" fill="white" />
      <path d="M406.959 539.373L408.807 539.084C409.358 539.403 417.658 544.26 418.128 553.7C418.238 557.445 417.191 561.133 415.13 564.262C413.07 567.39 410.095 569.809 406.611 571.187C348.334 580.486 291.24 596.086 236.331 617.714C235.938 617.56 227.02 613.873 225.519 604.409C224.375 596.971 228.665 591.577 230.126 589.995" fill="white" />
      <path d="M413.756 566.052L415.94 565.705C416.489 566.014 424.789 570.871 425.258 580.311C425.37 584.057 424.325 587.747 422.265 590.878C420.204 594.009 417.229 596.428 413.744 597.808C355.467 607.107 298.373 622.707 243.464 644.335C243.071 644.181 234.153 640.494 232.65 631.02C232.328 628.667 232.489 626.273 233.121 623.984C233.754 621.695 234.845 619.558 236.329 617.704" fill="white" />
      <path d="M396.498 600.751C406.937 616.796 397.604 649.465 376.936 668.11C352.068 690.478 320.588 683.653 317.914 683.024C291.637 676.834 267.679 653.955 268.095 635.146" fill="white" />
      <path className={animate ? 'bulb__main-stroke-animation ' : 'bulb__main-stroke '} d="M330.886 470.25C321.492 470.696 308.237 471.07 292.346 470.762C256.725 470.078 238.632 469.553 225.024 460.766C222.787 459.294 197.64 442.563 198.105 417.405C198.443 399.156 212.091 386.648 223.506 376.435C260.763 343.272 322.626 326.562 339.723 347.521C343.19 351.768 345.871 358.876 343.628 365.109C335.489 387.706 268.222 383.668 228.945 376.334C183.467 367.876 150.001 361.635 141.882 339.409C121.308 283.064 291.282 199.774 302.705 195.616C305.606 194.405 308.828 194.193 311.862 195.015C319.002 197.243 322.005 206.17 322.722 208.307C327.089 221.32 320.689 236.111 315.148 243.559C295.405 270.062 243.018 261.592 223.832 258.492C193.702 253.614 142.008 245.217 123.693 210.867C101.397 169.115 130.681 93.3335 208.361 42.4964" stroke="#8CE59C" strokeWidth="5" strokeMiterlimit="10" strokeLinecap="round" />
    </svg>
  )
}